<template>
  <div class="daily-container">
    <div class="box" v-for="item in list" :key="item.id" v-show="item.show">
      <div class="box-container" @click="jump(item.path)">
        <img :src="item.src" />
        <div class="box-title">{{ item.title }}</div>
        <div class="box-describe">{{ item.describe }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DailyMonitor",
  data() {
    return {
      list: [
        // 管理看板
        {
          id: 1,
          title: "管理看板",
          describe: "通过AI与大数据技术轻松高效管理分散型施工工地;",
          // src: require("./datav.jpg"),
          src: require("./dataImage/adminBoard.png"),
          path: "/BoardTotalView",
          name: "BoardTotal",
          show: true,
        },
        // 工地巡查大屏
        {
          id: 2,
          title: "工地巡查大屏",
          describe: "远程动态巡查工地，发现问题及时改进；",
          src: require("./dataImage/inspection.png"),
          path: "/PatrolView",
          name: "Patrol",
          show: true,
        },
        // 展厅数据看板
        {
          id: 3,
          title: "展厅数据看板",
          describe: "展厅大屏地图数据看板；",
          src: require("./dataImage/exhibitionHall.png"),
          path: "/ExhibitionBoardView",
          name: "ExhibitionBoard",
          show: true,
        },
        // 工地案场
        {
          id: 4,
          title: "工地案场",
          describe:
            "推选整洁度优、形象好样板工地轮播供观摩学习，提升企业品牌形象；",
          src: require("./dataImage/siteField.png"),
          path: "/CaseFieldView",
          name: "CaseField",
          show: true,
        },
        // 展厅案场
        {
          id: 5,
          title: "展厅案场",
          describe: "北京全包圆东店大屏专用案场，展厅大屏样板工地轮播",
          src: require("./dataImage/customPreviewView.png"),
          path: "/customPreviewView",
          name: "customPreview",
          show: true,
        },
        // 旧版管理端看板
        {
          id: 6,
          title: "旧版管理端看板",
          describe: "目前只有超级管理员可查看",
          // src: require("./dataImage/datav.jpg"),
          src: require("./dataImage/datav.png"),
          path: "/DataView",
          name: "DataV",
          show: true,
        },
        {
          id: 7,
          title: "地图案场看板",
          describe: "地图和工地案场结合",
          src: require("./dataImage/mapField.png"),
          path: "/MapFieldboardView",
          name: "MapFieldboard",
          show: true,
        },
        {
          id: 8,
          title: "直播案场",
          describe: "录制直播",
          src: require("./dataImage/videoRecord.png"),
          path: "/VideoRecordView",
          name: "VideoRecord",
          show: true,
        },
      ],
    };
  },
  created() {
    // console.log(sessionStorage.getItem("roles"));
  },
  mounted() {
    // console.log(sessionStorage.getItem("roles"));
    setTimeout(() => {
      let roles = sessionStorage.getItem("roles");
      let arr = roles.split(",");
      // console.log(arr);

      this.list.forEach((el) => {
        if (roles.indexOf(el.name) != -1) {
          el.show = true;
          // console.log(el.name);
        } else {
          el.show = false;
          // console.log("false", el.name);
        }
      });
    }, 100);
  },
  methods: {
    jump(path) {
      if (path) {
        const { href } = this.$router.resolve({ path });
        window.open(href, "_blank");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.daily-container {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.box {
  // width: 100%;
  height: 300px;
  width: 400px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: left;
  .box-container {
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
    height: 300px;
    &:hover {
      box-shadow: 5px 5px 10px #ccc;
      border: 1px solid #00d7d7;
    }
  }
  .box-title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
  .box-describe {
    height: 52px;
    padding: 0 10px 10px 10px;
  }
  img {
    width: 100%;
    height: 60%;
  }
}
</style>
